@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

html {
  @apply font-montserrat;
}

.ReactModal__Overlay {
  opacity: 0;
  /* padding-top: 60px; */
  /* transform: translate(-50%, -50%); */
  transition: opacity 200ms ease-in-out;
}
.ReactModal__Content {
  /* opacity: 1; */

  /* opacity: 0; */
  /* width: 500px;
  height: 500px;
  left: 50%;
  top: 50%; */
  /* display: none; */
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* margin-top: 100px; */
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
